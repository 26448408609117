<template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{$t("profile.infotexts.Privacy-Settings-title")}}</h2>
            <p>
              {{$t("profile.infotexts.Privacy-Settings")}}
            </p>

            <hr />
          </b-col>
          <b-col cols="12" class="privacy-headings ">
            <h3>{{$t("profile.contents.privacy-settings.title1")}}</h3>
            <p>{{$t("profile.contents.privacy-settings.text")}}</p>
          </b-col>
        </b-row>
        <b-row class="privacy-settings-content mb-10">
          <b-col cols="12" md="8" offset-md="4">
            <b-row >
              <b-col cols="4">
                <p>{{$t("profile.contents.privacy-settings.checktitle1")}}</p>
              </b-col>
              <b-col cols="4">
                <p>{{$t("profile.contents.privacy-settings.checktitle2")}}</p>
              </b-col>
              <b-col cols="4">
                <p>{{$t("profile.contents.privacy-settings.checktitle3")}}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="justify-content:space-between;     margin-bottom: 47px;">
          <b-col cols="4" class="pt-3">
            <h6>{{$t("profile.contents.privacy-settings.check1")}}</h6>
          </b-col>
          <b-col cols="8">
            <b-row>
              <b-col cols="4">
         
                <b-form-group v-slot="{ ariaDescribedby }">
                  
                  <b-form-checkbox
                    id="checkbox-group-1"
                    v-model="form.my_connections"
                    value="ALL"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox
                    id="checkbox-group-2"
                    v-model="form.my_connections"
                    value="MY_CONNECTIONS"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-2"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox
                    id="checkbox-group-3"
                    v-model="form.my_connections"
                    value="NONE"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="show-my-email" style="justify-content:space-between">
          <b-col cols="4" class="pt-3">
            <h6>{{$t("profile.contents.privacy-settings.check2")}}</h6>
          </b-col>
          <b-col cols="8">
            <b-row>
              <b-col cols="4">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox
                    id="checkbox-group-4"
                    v-model="form.show_email"
                    value="ALL"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox
                    id="checkbox-group-5"
                    v-model="form.show_email"
                    value="MY_CONNECTIONS"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-2"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox
                    id="checkbox-group-6"
                    v-model="form.show_email"
                    value="NONE"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-3"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <h4>{{$t("profile.contents.privacy-settings.title2")}}</h4>
            <b-row>
              <b-col cols="4">
                <p>{{$t("profile.contents.privacy-settings.check3")}}</p>
              </b-col>

              <b-col cols="8">
                
                <b-form-checkbox
                  v-model="form.show_search"
                  name="check-button"
                  switch
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-left">
            <b-button
              variant="success"
              class="privacy-save-btn"
              @click="saveSettings()"
              >{{$t("buttons.savechanges")}}</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>




<script>
import getPrivacy from "@/graphql/me/profile/getPrivacy.graphql";
import privacyUpdate from "@/graphql/me/profile/privacyUpdate.graphql";

export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {
    async getPrivacySettings() {
      try {
        let data = await this.$apollo.query(getPrivacy, {});
        let response = data("**.get", true);

        this.form = response;
        console.log(this.form);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async saveSettings() {
      try {
        let data = await this.$apollo.mutate(privacyUpdate, {
          privacy: this.form,
        });
        let response = data("**.update");
        if (response) {
         return this.$bvToast.toast(this.$t("Toaster.changes-saved"), {
            title: this.$t("Toaster.success-title"),
            variant: "success",
            autoHideDelay:1350,
          });
        }
      } catch (e) {
       
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.pwLoading = false;
      }
    },
  },
  created() {
    this.getPrivacySettings();
  },
};
</script>