var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"account-headers accounts-tabs-height",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('b-img',{attrs:{"src":"/img/account-icons.svg"}})],1),_c('b-col',{attrs:{"cols":"11"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("profile.infotexts.Privacy-Settings-title")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("profile.infotexts.Privacy-Settings"))+" ")]),_c('hr')]),_c('b-col',{staticClass:"privacy-headings ",attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.title1")))]),_c('p',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.text")))])])],1),_c('b-row',{staticClass:"privacy-settings-content mb-10"},[_c('b-col',{attrs:{"cols":"12","md":"8","offset-md":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.checktitle1")))])]),_c('b-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.checktitle2")))])]),_c('b-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.checktitle3")))])])],1)],1)],1),_c('b-row',{staticStyle:{"justify-content":"space-between","margin-bottom":"47px"}},[_c('b-col',{staticClass:"pt-3",attrs:{"cols":"4"}},[_c('h6',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.check1")))])]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-group-1","value":"ALL","options":_vm.options,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form.my_connections),callback:function ($$v) {_vm.$set(_vm.form, "my_connections", $$v)},expression:"form.my_connections"}})]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-group-2","value":"MY_CONNECTIONS","options":_vm.options,"aria-describedby":ariaDescribedby,"name":"flavour-2"},model:{value:(_vm.form.my_connections),callback:function ($$v) {_vm.$set(_vm.form, "my_connections", $$v)},expression:"form.my_connections"}})]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-group-3","value":"NONE","options":_vm.options,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form.my_connections),callback:function ($$v) {_vm.$set(_vm.form, "my_connections", $$v)},expression:"form.my_connections"}})]}}])})],1)],1)],1)],1),_c('b-row',{staticClass:"show-my-email",staticStyle:{"justify-content":"space-between"}},[_c('b-col',{staticClass:"pt-3",attrs:{"cols":"4"}},[_c('h6',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.check2")))])]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-group-4","value":"ALL","options":_vm.options,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form.show_email),callback:function ($$v) {_vm.$set(_vm.form, "show_email", $$v)},expression:"form.show_email"}})]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-group-5","value":"MY_CONNECTIONS","options":_vm.options,"aria-describedby":ariaDescribedby,"name":"flavour-2"},model:{value:(_vm.form.show_email),callback:function ($$v) {_vm.$set(_vm.form, "show_email", $$v)},expression:"form.show_email"}})]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-group-6","value":"NONE","options":_vm.options,"aria-describedby":ariaDescribedby,"name":"flavour-3"},model:{value:(_vm.form.show_email),callback:function ($$v) {_vm.$set(_vm.form, "show_email", $$v)},expression:"form.show_email"}})]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.title2")))]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(_vm._s(_vm.$t("profile.contents.privacy-settings.check3")))])]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.form.show_search),callback:function ($$v) {_vm.$set(_vm.form, "show_search", $$v)},expression:"form.show_search"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"privacy-save-btn",attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveSettings()}}},[_vm._v(_vm._s(_vm.$t("buttons.savechanges")))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }